import React from 'react'
import Reveal from 'react-reveal/Fade';


const FadeInUp = ({
  duration = 1000,
  children,
}) => (
    <Reveal bottom>
      {children}
    </Reveal>
  )

export default FadeInUp;
